import Logo from '@/components/logo'
import { Link } from '@inertiajs/react'
import { PropsWithChildren } from 'react'
import { SparklesCore } from '@/components/sparkles'
import { Toast } from '@/components/ui/toast'
import ApplicationLogo from '@/components/application-logo'
import { useTheme } from '@/components/theme-provider'

export default function GuestLayout({ children }: PropsWithChildren) {
    const { theme, setTheme } = useTheme()
    return (
        <div className="flex min-h-screen flex-col items-center space-y-4 sm:justify-center">
            <Toast />
            <div className="absolute inset-0 h-screen w-full">
                <SparklesCore
                    id="tsparticlesfullpage"
                    minSize={theme === 'light' ? 0.5 : 0.1}
                    maxSize={theme === 'light' ? 1.5 : 0.8}
                    particleDensity={20}
                    className="h-full w-full"
                    particleColor={theme === 'light' ? '#09090B' : '#FAFAFA'}
                    speed={2}
                />
            </div>
            <div className="z-10">
                <Link href="/">
                    <div className="flex items-center gap-x-2">
                        <ApplicationLogo className="size-5 dark:contrast-100 dark:invert" />
                        <Logo className="pr-8" />
                    </div>
                </Link>
            </div>

            <div className="z-10 w-full overflow-hidden sm:max-w-md">{children}</div>
        </div>
    )
}
